<template>
  <base-dialog
    :show="!!error"
    :errorIcon="!!error"
    title="Oops. Something went wrong"
    @close="handleError"
  >
    <p>{{ error }}</p>
  </base-dialog>
  <!-- Header -->
  <main-card-header :title="cardTitle"></main-card-header>
  <template v-if="!isLoading">
    <table-item
      :headers="tableHeaders"
      :signaturesObject="getSignatures"
    ></table-item>
  </template>
  <span v-else>
    <heart-rate fast="true"></heart-rate>
  </span>
  <my-pagination
    v-if="!!getSignatures.total"
    :options="options"
    v-model="page"
    :records="getSignatures.total"
    :per-page="getSignatures.per_page"
    @paginate="myCallback"
  >
  </my-pagination>
  <blank-paginate v-else />
</template>

<script>
import tableItem from "@/components/layout/CS/signatureTable.vue";
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import blankPaginate from "@/components/ui/baseComponents/blankPaginate.vue";
export default {
  components: { tableItem, blankPaginate },
  data() {
    return {
      error: null,
      options: {
        template: markRaw(customPaginate, blankPaginate),
      },
      page: 1,
      isLoading: false,
      cardTitle: "Policies Signature List",
      tableHeaders: { col1: "NAME", col2: "DOCUMENT", col3: "DATE" },
    };
  },
  created() {
    this.loadSignatures();
  },
  methods: {
    handleError() {
      this.error = null;
    },
    myCallback(e) {
      this.loadSignatures(e);
    },
    async loadSignatures(e) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("cleaner/loadSignatures", e);
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },
  computed: {
    getSignatures() {
      return this.$store.getters["cleaner/getSignatures"];
    },
  },
};
</script>
