<template>
  <div class="flex flex-wrap justify-center items-center">
    <div class="flex flex-wrap justify-center items-center w-11/12">
      <div class="container mx-auto px-4 sm:px-8 max-w-3xl">
        <div class="py-8">
          <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div
              class="w-full inline-block min-w-full shadow rounded-lg overflow-hidden"
            >
              <table class="min-w-full leading-normal table-auto w-full">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="px-5 py-3 bg-teal border-b text-white text-left text-sm uppercase font-normal"
                    >
                      {{ headers.col1 }}
                    </th>
                    <th
                      scope="col"
                      class="px-5 py-3 bg-teal border-b text-white text-left text-sm uppercase font-normal"
                    >
                      {{ headers.col2 }}
                    </th>
                    <th
                      scope="col"
                      class="px-5 py-3 bg-teal border-b text-white text-left text-sm uppercase font-normal"
                    >
                      {{ headers.col3 }}
                    </th>
                  </tr>
                </thead>

                <signature-table-item
                  :signatureObject="signaturesObject"
                ></signature-table-item>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import signatureTableItem from "./signatureTableItem.vue";
export default {
  components: { signatureTableItem },
  props: {
    signaturesObject: {
      type: Object,
      required: true,
    },
    headers: {
      type: Object,
      required: true,
    },
  },
};
</script>
