<template>
  <tbody>
    <!-- <template v-for="policy in signatureObject" :key="policy.id"> -->
    <!-- <template v-for="signature in policy.signatures" :key="signature.id"> -->
    <tr
      v-for="signature in signatureObject.data"
      :key="signature.id"
      class="px-5 py-5 border-b border-grey bg-lightGrey text-sm"
    >
      <td>
        <div class="flex items-center">
          <div class="ml-3">
            <p class="text-darkGrey whitespace-no-wrap">
              {{ signature.user.email }}
            </p>
          </div>
        </div>
      </td>
      <td class="px-5 py-5 border-b border-grey bg-lightGrey text-sm">
        <p v-if="!!signature.policy" class="text-darkGrey whitespace-no-wrap">
          {{ signature.policy.name }}
        </p>
      </td>
      <td class="px-5 py-5 border-b border-grey bg-lightGrey text-sm">
        <p class="text-darkGrey whitespace-no-wrap">
          {{ convertedTime(signature.created_at) }}
        </p>
      </td>
    </tr>
    <!-- </template> -->
    <!-- </template> -->
  </tbody>
</template>

<script>
import moment from "moment";
export default {
  props: {
    signatureObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { signatures: [] };
  },
  methods: {
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
};
</script>
